export const ChartMixedIcon = ({className}: {className: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-chart-network fa-w-20"
    data-icon="chart-network"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 512 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`${className} fa-group`}>
      <path
        className="fa-secondary"
        d="M192 224c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32zM64 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32zm224 0V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320c0-17.7-14.3-32-32-32s-32 14.3-32 32zm160-96c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32z"
        opacity=".4"
      />
      <path
        className="fa-primary"
        d="M505 44c11 13.8 8.8 33.9-5 45L340 217c-11.4 9.1-27.5 9.4-39.2 .6L192.6 136.5 52 249c-13.8 11-33.9 8.8-45-5s-8.8-33.9 5-45L172 71c11.4-9.1 27.5-9.4 39.2-.6l108.2 81.1L460 39c13.8-11 33.9-8.8 45 5z"
      />
    </g>
  </svg>
);
