export const TruckFastIcon = ({className}: {className: string}) => (
  <svg
    aria-hidden="true"
    className="svg-inline--fa fa-chart-network fa-w-20"
    data-icon="chart-network"
    data-prefix="fad"
    focusable="false"
    height="100%"
    role="img"
    viewBox="0 0 640 512"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className={`${className} fa-group`}>
      <path
        className="fa-secondary"
        d="M288 112c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H272c8.8 0 16 7.2 16 16zm-48 48c8.8 0 16 7.2 16 16s-7.2 16-16 16H48c-8.8 0-16-7.2-16-16s7.2-16 16-16H240zm-32 64c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H208zM96 432a80 80 0 1 1 160 0A80 80 0 1 1 96 432zm288 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z"
        opacity=".4"
      />
      <path
        className="fa-primary"
        d="M64 48C64 21.5 85.5 0 112 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576h-1.1c-7.8-54.3-54.4-96-110.9-96s-103.1 41.7-110.9 96H286.9c-7.8-54.3-54.4-96-110.9-96c-48.1 0-89.2 30.4-105 73c-4.4-7.3-7-15.8-7-25V256H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64V192H240c8.8 0 16-7.2 16-16s-7.2-16-16-16H64V128H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H64V48zM416 256H544V237.3L466.7 160H416v96z"
      />
    </g>
  </svg>
);
