import {Link, Outlet} from '@remix-run/react';
import {ChartMixedIcon} from '#/app/components/icons/ChartMixedIcon.jsx';
import {CustomerPortalIcon} from '#/app/components/icons/CustomerPortalIcon.jsx';
import {TruckFastIcon} from '#/app/components/icons/TruckFastIcon.jsx';

export default function RegisterLayout() {
  return (
    <div className="flex min-h-screen flex-col-reverse lg:flex-row">
      <div className="justify-centerx flex flex-1 flex-col items-center bg-sky-500 py-16 font-light text-white lg:w-1/2 lg:pb-0 lg:pt-24">
        <div className="w-8/12 pl-20 text-xl">
          <h1 className="mb-16 hidden text-5xl font-bold text-white lg:block">
            <span className="">⬢</span>
            {' '}
            ufleet
          </h1>
          <div className="-ml-20 space-y-8">
            <div className="flex w-full space-x-8">
              <div className="flex h-full w-12 shrink-0 items-start pt-4">
                <TruckFastIcon className="fill-white" />
              </div>
              <div className="space-y-1">
                <h5 className="text-2xl font-medium">Get Started Quickly</h5>
                <p className="font-light">We’ll assist you with the setup, show you around Ufleet, and help you make the most of it.</p>
              </div>
            </div>
            <div className="flex w-full space-x-8">
              <div className="flex h-full w-12 shrink-0 items-start pt-4">
                <CustomerPortalIcon color="white" />
              </div>
              <div className="space-y-1">
                <h5 className="text-2xl font-medium">Everything is Included</h5>
                <p className="font-light">Everything you need for smooth and efficient delivery operations is included at no extra cost.</p>
              </div>
            </div>
            <div className="flex w-full space-x-8">
              <div className="flex h-full w-12 shrink-0 items-start pt-4">
                <ChartMixedIcon className="fill-white" />
              </div>
              <div className="space-y-1">
                <h5 className="text-2xl font-medium">Pay as You Go</h5>
                <p className="font-light">FREE for businesses with up to 200 tasks/month. For everyone else, it's just $0.15 per task.</p>
              </div>
            </div>
          </div>
          <p className="mt-8 text-lg lg:mt-16">
            Questions? Need a hand?
            {' '}
            <Link className="underline" to="mailto:hello@ufleet.io">
              Support can help
            </Link>
            .
          </p>
        </div>
      </div>
      <div className="flex flex-1 justify-center space-y-4 lg:w-1/2">
        <Outlet />
      </div>
    </div>
  );
}
